<template>
  <div>
    <a class="columns is-vcentered is-multiline is-mobile" :class="visible ? 'has-text-dark' : 'has-text-grey'" @click="toggle">
      <div class="column">
        <button v-if="isButton" class="button is-info">
          <span class="has-text-weight-semibold" :class="[isLarge ? 'is-5' : 'is-6']">
            <span class="is-valigned">{{ title }}</span>
          </span>
        </button>
        <span v-if="!isButton" class="has-text-weight-semibold" :class="[isLarge ? 'is-5' : 'is-6']">
          <span class="is-valigned">{{ title }}</span>
        </span>
      </div>
      <div class="column is-narrow">
        <span class="icon is-valigned is-small is-info">
          <i
            class="fal fa-chevron-down"
            :class="[visible ? 'visible' : 'invisible']"
          />
        </span>
      </div>
    </a>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ToggleHeader',
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: () => 'N/A'
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.visible, .invisible
  transition: all .5s ease
.visible
  transform: rotate(180deg)
.invisible
  transform: rotate(0deg)
</style>
